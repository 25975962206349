<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div class="w-full bg-primary">
  <div class="content pt-16 pb-16">
    <app-breadcrumbs [segments]="breadcrumbs"></app-breadcrumbs>

    <div class="flex flex-col gap-4 my-8 mx-2 xl:mx-0">
      <div class="flex gap-4 items-center w-full justify-between">
        <h1 class="text-white font-bold text-3xl">Cloud Compute Prices</h1>
        <button class="btn-primary py-2 px-3 flex gap-1 items-center" (click)="clipboardURL()">
          Share
          <lucide-icon name="{{clipboardIcon}}" size="16" class="ml-2"></lucide-icon>
        </button>
      </div>
      <div class="text-white text-xl pr-16">
        Explore, search, and evaluate cloud compute resources and their pricing in the table below. This comprehensive comparison includes diverse attributes such as CPU count, detailed processor information, memory, GPU, storage, network speed and capacity, available operating systems, and pricing models like spot and on-demand. Use the sidebar to filter the results, or enter your freetext query in the "Search prompt" bar.
      </div>
    </div>

    <div class="flex gap-8 mx-2 xl:mx-0">
      <div class="filter_bar bg-primary"
        [ngClass]="{
          'collapsed': isCollapsed
        }">
        <div class="collapser bg-secondary" (click)="toggleCollapse()" tabindex="0"
             data-ph-capture-attribute-sc-event="server listing toggle sidebar">
          <lucide-icon name="chevron-left" class="h-6 w-6 text-emerald-400"></lucide-icon>
        </div>
       <app-search-bar
       [query]="query"
       [filterCategories]="filterCategories"
       [searchParameters]="searchParameters"
       [selectedCurrency]="selectedCurrency"
       AIAssistantType="server_prices"
       (searchChanged)="searchBarChanged($event)"
       > </app-search-bar>
      </div>
      <div class=" list_holder flex flex-col gap-4"
          [ngClass]="{
            'limited-full': !isCollapsed,
            'w-full': isCollapsed
          }">
        <div class="top_buttons w-full flex justify-end gap-6 items-center" >
          <div>
            <button
              id="allocation_button"
              data-ph-capture-attribute-sc-event="server listing price allocation select"
              data-dropdown-toggle="allocation_options"
              class="dropdown_button"
              type="button">
              <div class="px-4 py-3">
                {{allocation.name}}
              </div>
              <div class="px-4 py-3 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
              </div>
            </button>
          </div>
          <div>
            <button
              id="currency_button"
              data-ph-capture-attribute-sc-event="server listing currency select"
              data-dropdown-toggle="currency_options"
              class="dropdown_button"
              type="button">
              <div class="px-4 py-3">
              {{selectedCurrency.slug}}
              </div>
              <div class="px-4 py-3 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
              </div>
            </button>
          </div>
          <div>
            <button
              id="column_button"
              data-ph-capture-attribute-sc-event="server listing columns select"
              data-dropdown-toggle="column_options"
              class="dropdown_button"
              type="button">
              <div class="px-4 py-3">
              Columns
              </div>
              <div class="px-4 py-3 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
              </div>
            </button>
          </div>
        </div>
        <div>
          <div class="w-full overflow-x-auto relative" style="padding-left: 1px;">
            <div *ngIf="isLoading" class="absolute top-0 left-0 w-full h-full pt-16" style="background-color: rgba(0,0,0,0.2);" >
              <app-loading-spinner size="lg"></app-loading-spinner>
            </div>
            <table class="items_table" id="server_prices_table">
              <thead>
                <tr>
                  <th class="checkbox_column">
                    <div class="text-white">
                      <lucide-icon class="h-4 w-4" name="scale" ></lucide-icon>
                    </div>
                  </th>
                  <th *ngFor="let item of tableColumns"
                      [ngClass]="{
                        'cursor-pointer': item.orderField
                      }"
                      (click)="toggleOrdering(item)" >
                      <div class="flex gap-1">
                        {{item.name}}
                        <lucide-icon
                          *ngIf="item.info"
                          name="info"
                          (mouseenter)="showTooltip($event, item.info)"
                          (mouseleave)="hideTooltip()"
                          class="h-4 w-4 text-gray-200">
                        </lucide-icon>
                        <lucide-icon *ngIf="getOrderingIcon(item)" name="{{getOrderingIcon(item)}}" class="h-4 w-4 text-white"></lucide-icon>
                      </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of servers; let i = index" (click)="openServerDetails(item)"
                    data-ph-capture-attribute-sc-event="server listing route to server details"
                    [attr.data-ph-capture-attribute-server-listing-row-vendor]="item.vendor_id"
                    [attr.data-ph-capture-attribute-server-listing-row-region]="item.region.api_reference"
                    [attr.data-ph-capture-attribute-server-listing-row-zone]="item.zone.api_reference"
                    [attr.data-ph-capture-attribute-server-listing-row-server]="item.server.api_reference"
                    [attr.data-ph-capture-attribute-server-listing-row-index]="i+1"
                    [attr.data-ph-capture-attribute-server-listing-row-page-size]="limit"
                    id="server_table_data_line">

                  <td (click)="toggleCompare2($event, item)" class="checkbox_column">
                    <div class="flex items-center">
                      <input type="checkbox"
                        id="server_compare_checkbox_{{item.server_id}}"
                        [(ngModel)]="item.selected"
                        (ngModelChange)="toggleCompare($event, item)"
                        (click)="$event.stopPropagation()"
                        class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none cursor-pointer"
                        [ngClass]="{'partially_selected': item.partiallySelected && !item.selected }" >
                    </div>
                  </td>
                  <ng-container *ngFor="let column of tableColumns">
                    <td *ngIf="column.type === 'name'" class="min-w-225">
                      <div class="text-sm text-white flex gap-1">
                        <span>{{item.server.display_name}} </span>
                        <span *ngIf="showAPIReference(item)" class="text-emerald-400" >|</span>
                        <span *ngIf="showAPIReference(item)">{{item.server.api_reference}} </span>
                      </div>
                      <div class="text-xs text-white flex gap-1">
                        <span class="whitespace-nowrap">{{item.vendor_id}}</span>
                        <span class="text-emerald-400" >|</span>
                        <span class="whitespace-nowrap">{{item.region.display_name}}</span>
                        <span class="text-emerald-400" >|</span>
                        <span class="whitespace-nowrap">{{item.zone.display_name}}</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'processor'">
                      <div class="text-sm text-white whitespace-nowrap">{{item.server.vcpus}} vCPUs ({{item.server.cpu_architecture}})</div>
                      <div class="flex gap-1">
                        <div
                          *ngIf="item.server.cpu_cores && item.server.cpu_speed"
                          class="text-xs text-white flex gap-1">
                          <span class="whitespace-nowrap">{{item.server.cpu_cores}} cores &#64; {{item.server.cpu_speed}} GHz</span>
                        </div>
                        <div
                          *ngIf="item.server.cpu_allocation && item.server.cpu_allocation !== 'Dedicated'"
                          class="text-xs text-white flex gap-1">
                          <span>({{item.server.cpu_allocation}})</span>
                        </div>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'memory'">
                      {{getMemory(item)}}
                    </td>
                    <td *ngIf="column.type === 'score'">
                      <div class="text-sm text-white">{{getScore(item.server.score)}}</div>
                      <div
                        *ngIf="item.server.score_per_price"
                        class="text-xs text-white flex gap-1">
                        <span class="whitespace-nowrap">{{getScore(item.server.score_per_price)}} / USD</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'score_per_price'">
                      <span *ngIf="item.server.score_per_price">{{getScore(item.server.score_per_price)}} / USD</span>
                      <span *ngIf="!item.server.score_per_price">-</span>
                    </td>
                    <td *ngIf="column.type === 'gpu_memory'">
                      {{getGPUMemory(item)}}
                    </td>
                    <td *ngIf="column.type === 'storage'">
                      <div class="text-sm text-white">{{getStorage(item)}}</div>
                      <div
                        *ngIf="item.server.storage_type"
                        class="text-xs text-white">
                        <span>{{item.server.storage_type}}</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'price'" class="min-w-160">
                      <div class="text-sm text-white">{{item.price}} {{selectedCurrency.slug}}</div>
                      <div
                        class="text-xs text-white">
                        {{getAllocationName(item.allocation)}}
                      </div>
                    </td>
                    <td *ngIf="column.type === 'gpu'">
                      <div class="text-sm text-white">{{item.server.gpu_count}}</div>
                      <div
                        *ngIf="item.server.gpu_model"
                        class="text-xs text-white flex gap-1">
                        <span>{{item.server.gpu_model}}</span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'cpu_model'">
                      <div
                        *ngIf="item.server.cpu_model"
                        class="text-sm text-white flex gap-1">
                        <span>{{item.server.cpu_model}}</span>
                      </div>
                      <div class="text-xs text-white">
                        <span *ngIf="item.server.cpu_manufacturer">{{item.server.cpu_manufacturer}} </span>
                        <span *ngIf="item.server.cpu_family">{{item.server.cpu_family}} </span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'gpu_model'">
                      <div
                        *ngIf="item.server.gpu_model"
                        class="text-sm text-white flex gap-1">
                        <span>{{item.server.gpu_model}}</span>
                      </div>
                      <div class="text-xs text-white">
                        <span *ngIf="item.server.gpu_manufacturer">{{item.server.gpu_manufacturer}} </span>
                        <span *ngIf="item.server.gpu_family">{{item.server.gpu_family}} </span>
                      </div>
                    </td>
                    <td *ngIf="column.type === 'text' && column.key">
                      {{getField(item, column.key)}}
                    </td>
                    <td *ngIf="column.type === 'country'">
                      {{item.region.country_id | countryIdtoName}}
                    </td>
                    <td *ngIf="column.type === 'region'" class="min-w-225">
                      <div class="text-sm text-white">{{item.region.display_name}}</div>
                      <div class="text-xs text-white flex gap-1"> <span>{{item.region.region_id}} </span> </div>
                    </td>
                    <td *ngIf="column.type === 'vendor'" >
                      <div *ngIf="item.vendor.logo" class="w-9 h-9 px-1 py-1 bg-white rounded-lg">
                        <img [src]="item.vendor.logo" alt="{{item.vendor.name}} logo" >
                      </div>
                      <div *ngIf="!item.vendor.logo" class="text-white text-sm">
                          {{item.vendor.name}}
                      </div>
                    </td>
                  </ng-container>
                  <td>
                    <!-- eslint-disable-next-line @angular-eslint/template/interactive-supports-focus -->
                    <div (click)="$event.stopPropagation()">
                      <a routerLink="/server/{{item.vendor.vendor_id}}/{{item.server.api_reference}}"
                         data-ph-capture-attribute-sc-event="server listing route to server details"
                         [attr.data-ph-capture-attribute-server-listing-row-vendor]="item.vendor_id"
                         [attr.data-ph-capture-attribute-server-listing-row-region]="item.region.api_reference"
                         [attr.data-ph-capture-attribute-server-listing-row-zone]="item.zone.api_reference"
                         [attr.data-ph-capture-attribute-server-listing-row-server]="item.server.api_reference"
                         [attr.data-ph-capture-attribute-server-listing-row-index]="i+1"
                         [attr.data-ph-capture-attribute-server-listing-row-page-size]="limit">
                        <lucide-icon name="chevron-right" class="h-6 w-6 text-emerald-400"></lucide-icon>
                      </a>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <div class="flex justify-between mt-4">
              <div>
                <button
                  id="pagesize_button"
                  data-ph-capture-attribute-sc-event="server listing page size select"
                  data-dropdown-toggle="pagesize_options"
                  class="dropdown_button"
                  type="button">
                  <div class="px-4 py-2">
                  {{limit}}
                  </div>
                  <div class="px-2 py-2 w-12 h-full bg-primary justify-center items-center flex rounded-r-lg">
                    <lucide-icon name="chevron-down" class="h-6 w-6 text-emerald-400"></lucide-icon>
                  </div>
                </button>
                <span class="text-white" > items per page</span>
              </div>
              <app-pagination
                baseURL="/server_prices"
                [currentPage]="page"
                [totalPages]="totalPages"
                [baseQueryParams]="getQueryObjectBase()">
              </app-pagination>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
</div>

<div *ngIf="compareCount() >= 2" class="fixed_buttons">
  <div class="flex gap-4" >
    <button
      class="flex justify-center bg-red-400 text-white py-2 px-4 rounded-lg font-bold hover:bg-red-500"
      data-ph-capture-attribute-sc-event="server listing compare"
      data-ph-capture-attribute-server-listing-compare-action="open"
      (click)="clearCompare()">
      Clear
    </button>
    <button
      class="flex justify-center bg-emerald-400 text-white py-2 px-4 rounded-lg font-bold hover:bg-emerald-500"
      data-ph-capture-attribute-sc-event="server listing compare"
      data-ph-capture-attribute-server-listing-compare-action="open"
      (click)="openCompare()">
      Compare ({{compareCount()}})
    </button>
  </div>
</div>

 <!-- Dropdown menu -->
 <div id="allocation_options" class="z-10 hidden bg-secondary rounded-lg shadow w-44 border border-emerald-400 border-solid">
  <ul class="py-2 px-1 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of allocationTypes"
      class="flex py-1 px-2 gap-2 items-center cursor-pointer hover:bg-emerald-500 rounded-sm"
      (click)="selectAllocation(item)" tabindex="0">
      <div class="text-white text-sm">{{item.name}} </div>
    </li>
  </ul>
</div>

<div id="pagesize_options" class="z-10 hidden bg-secondary rounded-lg shadow w-44 border border-emerald-400 border-solid">
  <ul class="py-2 px-1 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of pageLimits"
      class="flex py-1 px-2 gap-2 items-center cursor-pointer hover:bg-emerald-500 rounded-sm"
      (click)="selectPageSize(item)" tabindex="0">
      <div class="text-white text-sm">{{item}} </div>
    </li>
  </ul>
</div>

<div id="column_options" class="z-10 hidden bg-secondary rounded-lg shadow w-52 border border-emerald-400 border-solid">
  <ul class="py-2 px-3 text-sm text-gray-700" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of possibleColumns" class="flex py-1 gap-2 items-center">
      <input type="checkbox"
      [(ngModel)]="item.show"
      (ngModelChange)="refreshColumns()"
      class="focus:ring-emerald-400 accent-emerald-400 text-emerald-400 outline-none cursor-pointer" >
      <div class="text-white text-sm">{{item.name}}</div>
    </li>
  </ul>
</div>

<div id="currency_options" class="z-10 hidden bg-secondary rounded-lg shadow w-52 border border-emerald-400 border-solid">
  <ul class="py-2 px-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
    <li *ngFor="let item of availableCurrencies"
      class="flex py-1 px-2 gap-2 items-center cursor-pointer hover:bg-emerald-500 rounded-sm"
      (click)="selectCurrency(item)" tabindex="0">
      <div class="text-white text-sm ">{{item.name}} ({{item.symbol}})</div>
    </li>
  </ul>
</div>


<div id="tooltipDefault"
  #tooltipDefault
  style="opacity: 0;"
  class="absolute z-10 inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-secondary border border-solid border-emerald-400 rounded-lg">
  {{tooltipContent}}
</div>
